import Layout from "../components/Layout";
import React from 'react';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import "swiper/swiper-bundle.css";
import { useEffect, useState } from "react";
import axios from "axios";
import BlogSection from "../components/BlogSection";
import { Helmet } from "react-helmet";
import { animateScroll } from 'react-scroll';


export default function Home(props) {
    SwiperCore.use([Navigation]);


    const [homePage, setHomePage] = useState();
    const [memberPopup, setMemberPopup] = useState(null);

    useEffect(() => {
        axios.post('home-page').then(response => {
            setHomePage(response.data);
            window.triggerScroll();
        });
    }, []);

    useEffect(() => {
        document.querySelector('body').style.overflow = memberPopup ? 'hidden' : null;
        document.querySelector('html').style.overflow = memberPopup ? 'hidden' : null;
    }, [memberPopup]);

    return (
        <Layout generalData={props.generalData} setActiveAboutSection={props.setActiveAboutSection} activePage="home">
            <Helmet>
                <title>{props.seoPage.title}</title>
                <meta name="title" content={props.seoPage.title} />
                <meta name="description" content={props.seoPage.description} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location} />
                <meta property="og:title" content={props.seoPage.title} />
                <meta property="og:description" content={props.seoPage.description} />
                <meta property="og:image" content={props.seoPage.image_full_path} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location} />
                <meta property="twitter:title" content={props.seoPage.title} />
                <meta property="twitter:description" content={props.seoPage.description} />
                <meta property="twitter:image" content={props.seoPage.image_full_path} />
            </Helmet>
            {
                homePage ?
                    <>
                        <div className="ratio home-header" animate="down">
                            <img src={homePage.homeSettings.banner_image_path} alt="banner" />
                            <div className="opacity">
                                <img className="header-logo" src={homePage.homeSettings.banner_logo_path} alt="logo" animate="down" />
                                <div className="section-1">
                                    <div className="container">
                                        <div className="text-end">
                                            <div onClick={() => document.getElementById('home-section-1').scrollIntoView()}>
                                                <svg className="arrow-section" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 82 82" onClick={() => animateScroll.scrollTo(850)}>
                                                    <g id="Group_117" data-name="Group 117" transform="translate(82 82) rotate(180)">
                                                        <g id="Group_167" data-name="Group 167">
                                                            <path id="Path_14" data-name="Path 14" d="M41,0A41,41,0,1,1,0,41,41,41,0,0,1,41,0Z" transform="translate(82) rotate(90)" fill="#9b4493" />
                                                            <g id="Group_19" data-name="Group 19" transform="translate(1419.996 -22.45) rotate(90)">
                                                                <g id="Group_17" data-name="Group 17" transform="translate(51.45 1362.118)">
                                                                    <path className="stroke-w" id="Path_10" data-name="Path 10" d="M786.265,584.943l-16.878,16.878L786.265,618.7" transform="translate(-769.387 -584.943)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="4" />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container position-relative py-5 pb-lg-5 pb-0" id="home-section-1">
                            <div className="home-section-1 py-4">
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-md-6 col-sm-7 col-8">
                                        <div className="position-relative">
                                            <Link to="/about">
                                                <img className="circle-1" src="/assets/images/circle-rotate.svg" alt="circle" />
                                                <h1 className="inside-text text-center" animate="left">{homePage.homeSettings.about_us_title}</h1>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-12 about-section" animate="right">
                                        <div dangerouslySetInnerHTML={{ __html: homePage.homeSettings.about_us_description }} />
                                        <Link className="read-more" to="/about">{homePage.homeSettings.about_us_read_more_label}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="line"></div>
                        <div className="container-fluid ">
                            <div className="row section-3 align-items-center">
                                <div className="col-lg-8 col-md-8 col-sm-12 p-0 order-lg-1 order-2" animate="left">
                                    <>
                                        <Swiper loop={true} className="mySwiper" slidesPerView={3} spaceBetween={0} navigation={{
                                            nextEl: '.swiper-button-next',
                                            prevEl: '.swiper-button-prev'
                                        }}>

                                            <div className="swiper-button-prev">
                                                <svg className="arrow-swiper" id="Group_167" data-name="Group 167" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 56 56">
                                                    <circle id="Ellipse_15" data-name="Ellipse 15" cx="28" cy="28" r="28" fill="#9b4493" />
                                                    <g id="Group_17" data-name="Group 17" transform="translate(14.988 17.051)">
                                                        <line className="stoke-swiper-w" id="Line_8" data-name="Line 8" x1="25.621" transform="translate(0.429 11.232)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                                                        <path className="stoke-swiper-w" id="Path_10" data-name="Path 10" d="M780.269,584.943l-10.882,10.882,10.882,10.882" transform="translate(-769.387 -584.943)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                                                    </g>
                                                </svg>

                                            </div>
                                            {
                                                homePage.boardMembers.map(boardMember => (
                                                    <SwiperSlide key={boardMember.id}>
                                                        <div className="ratio ratio-1x1 swiper-image-member">
                                                            <img className="swiper-image" src={boardMember.featured_image_path} alt="members" />
                                                            <img className="member-hover" src="/assets/images/hover-circle.svg" alt="member" onClick={() => setMemberPopup(boardMember)} />
                                                            <p className="member-name">{boardMember.full_name}</p>
                                                        </div>
                                                    </SwiperSlide>
                                                ))
                                            }
                                            <div className="swiper-button-next">
                                                <svg className="arrow-swiper" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 56 56">
                                                    <g id="Component_59_4" data-name="Component 59 – 4" transform="translate(56 56) rotate(180)">
                                                        <circle id="Ellipse_15" data-name="Ellipse 15" cx="28" cy="28" r="28" fill="#9b4493" />
                                                        <g id="Group_18" data-name="Group 18" transform="translate(14.988 17.051)">
                                                            <g id="Group_17" data-name="Group 17">
                                                                <line className="stoke-swiper-w" id="Line_8" data-name="Line 8" x1="25.621" transform="translate(0.429 11.232)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                                                                <path className="stoke-swiper-w" id="Path_10" data-name="Path 10" d="M780.269,584.943l-10.882,10.882,10.882,10.882" transform="translate(-769.387 -584.943)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                        </Swiper>
                                    </>
                                </div>

                                <div className="col-lg-3 col-md-3 col-sm-3 ms-lg-5 ms-md-4 ms-sm-3 ms-0 order-lg-2 order-md-2 order-sm-1" animate="right">
                                    <div className="board-members">
                                        <Link to="/about">
                                            <h1 className="my-2">{homePage.homeSettings.board_members_title}</h1>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="line"></div>
                        <div className="position-relative background-section py-5" style={{ backgroundImage: 'url(' + homePage.homeSettings.genetic_counselor_image_path + ')' }}>

                            <div className="py-lg-5">
                                <div className="py-lg-5 p-3">
                                    {/* <img className="background" src={homePage.homeSettings.genetic_counselor_image_path} alt="" /> */}
                                    <div className="container py-lg-5">
                                        <div className="row" animate="left">
                                            <div className="col-lg-5 col-md-6 col-sm-7 col-8">
                                                <div className="position-relative">
                                                    <Link to="/find-a-genetic-counselor">
                                                        <img className="circle-1" src="/assets/images/circle-rotate.svg" alt="circle" />
                                                        <h1 className="inside-text ms-5 px-lg-5">
                                                            {homePage.homeSettings.genetic_counselor_title}
                                                        </h1>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="line"></div>

                        <div className="py-5">
                            <div className="container section-4 py-lg-5" >
                                <h1 className="mb-lg-5 mb-4 text-center text-lg-start" animate="down">
                                    <Link to="/blog">
                                        {homePage.homeSettings.blog_title}
                                    </Link>
                                </h1>
                                <BlogSection
                                    slug={homePage.latestBlog.slug}
                                    image={homePage.latestBlog.featured_image_path}
                                    author={homePage.latestBlog.author_name}
                                    title={homePage.latestBlog.title}
                                    description={homePage.latestBlog.description}
                                    button={props.generalData.blogSettings.read_more_label}
                                />
                            </div>
                        </div>

                        <div className={"background-popup " + (memberPopup ? "" : "fade-out")}>
                            <div className="container position-relative">
                                <div className="close-x" onClick={() => setMemberPopup(null)}>
                                    <svg className="close-popup" id="Group_167" data-name="Group 167" xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82" >
                                        <path id="Path_14" data-name="Path 14" d="M41,0A41,41,0,1,1,0,41,41,41,0,0,1,41,0Z" transform="translate(82 82) rotate(180)" fill="#9b4493" />
                                        <g id="Group_64" data-name="Group 64" transform="translate(0.001 -0.118)">
                                            <path className="strokeWidth" id="Path_57" data-name="Path 57" d="M780.269,584.943l-10.882,10.882,10.882,10.882" transform="translate(810.387 636.944) rotate(180)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                                            <path className="strokeWidth" id="Path_58" data-name="Path 58" d="M780.269,584.943l-10.882,10.882,10.882,10.882" transform="translate(-728.389 -554.708)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                                        </g>
                                    </svg>
                                </div>
                            </div>

                            <div className="container h-100 py-lg-5 py-sm-3 py-3">
                                <div className="row justify-content-center align-items-center h-100 flex-row flex-md-row flex-sm-row">
                                    <div className="col-lg-4 col-md-5 order-lg-1 order-2 mx-lg-5 ">
                                        <div className="mx-auto">
                                            <div className="ratio ratio-1x1 image-member">
                                                <img src={memberPopup?.featured_image_path} alt="member" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-5 col-md-5  order-lg-2 order-md-2 order-1 mx-lg-5">
                                        <div className="popup-content">
                                            <h3 className="mb-2">{memberPopup?.full_name}</h3>
                                            <h5 className="mb-2">{memberPopup?.position}</h5>
                                            <div dangerouslySetInnerHTML={{ __html: memberPopup?.description }} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                    :
                    null
            }


        </Layout >

    )
}