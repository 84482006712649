import Layout from "../components/Layout";
// import Select from 'react-select';
import { useEffect, useState } from "react";
import Banner from "../components/Banner";
import Header from "../components/Header";
import axios from "axios";
import CustomSelect from "../components/CustomSelect";
import { Helmet } from "react-helmet";

let allOption = {
    value: '',
    label: 'All',
}

export default function FindaGeneticCounselor(props) {

    const [names, setNames] = useState([]);
    const [countries, setCountries] = useState([]);
    const [patientFacings, setPatientFacings] = useState([]);
    const [specialities, setSpecialities] = useState([]);

    const [selectedNames, setSelectedNames] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedPatientFacings, setSelectedPatiendFacings] = useState([]);
    const [selectedSpecialties, setSelectedSpecialties] = useState([]);

    const [counselors, setCounselors] = useState();
    const [memberPopup, setMemberPopup] = useState(null);

    useEffect(() => {
        window.triggerScroll();

        axios.post('genetic-counselors-page').then(response => {
            let newNames = [];
            newNames.push(allOption);
            response.data.names.forEach(name => {
                newNames.push({
                    value: name.name,
                    label: name.name,
                });
            });
            setNames(newNames);

            let newCountries = [];
            newCountries.push(allOption);

            response.data.countriesUpdated.forEach(country => {
                newCountries.push({
                    value: country.id,
                    label: country.name,
                });
            });
            setCountries(newCountries);

            let newSpecialities = [];
            newSpecialities.push(allOption);

            response.data.specialitiesUpdated.forEach(speciality => {
                newSpecialities.push({
                    value: speciality.id,
                    label: speciality.name,
                });
            });
            setSpecialities(newSpecialities);

            let newPatientFacings = [];
            newPatientFacings.push(allOption);

            response.data.patientFacingsUpdated.forEach(patientFacing => {
                newPatientFacings.push({
                    value: patientFacing.id,
                    label: patientFacing.name,
                });
            });
            setPatientFacings(newPatientFacings);

            setCounselors(response.data.geneticCounselors);
            window.triggerScroll();
        });
    }, []);

    const clearFilters = () => {
        setSelectedNames([]);
        setSelectedCountries([]);
        setSelectedPatiendFacings([]);
        setSelectedSpecialties([]);
    }

    const checkBoardMemberCountry = (boardMember) => {
        let countryFound = false;
        boardMember.countries.forEach(country => {
            if (country.id === selectedCountries.value) {
                countryFound = true;
            }
        });
        return countryFound;
    }


    const checkBoardMemberSpecialty = (boardMember) => {
        let specialityFound = false;
        boardMember.specialities.forEach(speciality => {
            if (speciality.id === selectedSpecialties.value) {
                specialityFound = true;
            }
        });
        return specialityFound;
    }

    const checkBoardMemberPatientFacing = (boardMember) => {
        let patientFacingFound = false;
        boardMember.patient_facings.forEach(patientFacings => {
            if (patientFacings.id === selectedPatientFacings.value) {
                patientFacingFound = true;
            }
        });
        return patientFacingFound;
    }

    const checkIfHasAllOption = (array) => {
        let hasAllOption = false;
        array.forEach(obj => {
            if (obj.value === allOption.value && obj.label === allOption.label) {
                hasAllOption = true;
            }
        });
        return hasAllOption;
    }

    const removeAllOption = (array) => {
        let newArray = [];
        array.forEach(obj => {
            if (obj.value !== allOption.value && obj.label !== allOption.label) {
                newArray.push(obj);
            }
        });
        return newArray;
    }

    const onChangeMultiSelect = (oldState, setState, r) => {
        let newState = r;

        if (checkIfHasAllOption(oldState)) {
            newState = removeAllOption(r);
        }

        if (!checkIfHasAllOption(oldState) && checkIfHasAllOption(r)) {
            newState = [allOption];
        }

        setState(newState);
    }

    const filterByName = (boardMember) => {
        if (selectedNames.length > 0) {
            if (selectedNames[0].label === allOption.label && selectedNames[0].value === allOption.value) {
                // All is selected
                return true;
            } else {
                // All is not selected check if board member name is available
                let boardMemberFiltered = false;
                selectedNames.forEach(selectedName => {
                    if (selectedName.value === boardMember.name) {
                        boardMemberFiltered = true;
                    }
                });
                return boardMemberFiltered;
            }
        } else {
            // Nothing is selected
            return false;
        }
    }

    const filterByCountries = (boardMember) => {
        if (selectedCountries.length > 0) {
            if (selectedCountries[0].label === allOption.label && selectedCountries[0].value === allOption.value) {
                // All is selected
                return true;
            } else {
                // All is not selected check if board member name is available
                let boardMemberFiltered = false;
                for (let i = 0; i < selectedCountries.length; i++) {
                    for (let j = 0; j < boardMember.countries.length; j++) {
                        if (selectedCountries[i].value === boardMember.countries[j].id) {
                            boardMemberFiltered = true;
                        }
                    }
                }
                return boardMemberFiltered;
            }
        } else {
            // Nothing is selected
            return false;
        }
    }

    const filterBySpecialties = (boardMember) => {
        if (selectedSpecialties.length > 0) {
            if (selectedSpecialties[0].label === allOption.label && selectedSpecialties[0].value === allOption.value) {
                // All is selected
                return true;
            } else {
                // All is not selected check if board member name is available
                let boardMemberFiltered = false;
                for (let i = 0; i < selectedSpecialties.length; i++) {
                    for (let j = 0; j < boardMember.specialities.length; j++) {
                        if (selectedSpecialties[i].value === boardMember.specialities[j].id) {
                            boardMemberFiltered = true;
                        }
                    }
                }
                return boardMemberFiltered;
            }
        } else {
            // Nothing is selected
            return false;
        }
    }

    const filterByPatientFacings = (boardMember) => {
        if (selectedPatientFacings.length > 0) {
            if (selectedPatientFacings[0].label === allOption.label && selectedPatientFacings[0].value === allOption.value) {
                // All is selected
                return true;
            } else {
                // All is not selected check if board member name is available
                let boardMemberFiltered = false;
                for (let i = 0; i < selectedPatientFacings.length; i++) {
                    for (let j = 0; j < boardMember.patient_facings.length; j++) {
                        if (selectedPatientFacings[i].value === boardMember.patient_facings[j].id) {
                            boardMemberFiltered = true;
                        }
                    }
                }
                return boardMemberFiltered;
            }
        } else {
            // Nothing is selected
            return false;
        }
    }

    useEffect(() => {
        document.querySelector('body').style.overflow = memberPopup ? 'hidden' : null;
        document.querySelector('html').style.overflow = memberPopup ? 'hidden' : null;
    }, [memberPopup]);

    return (
        <Layout generalData={props.generalData} setActiveAboutSection={props.setActiveAboutSection} activePage="find-a-genetic-counselor">

            <Helmet>
                <title>{props.seoPage.title}</title>
                <meta name="title" content={props.seoPage.title} />
                {props.seoPage.description ?
                    <meta name="description" content={props.seoPage.description} />
                    :
                    null
                }
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location} />
                <meta property="og:title" content={props.seoPage.title} />
                {props.seoPage.description ?
                    <meta property="og:description" content={props.seoPage.description} />
                    :
                    null
                }
                <meta property="og:image" content={props.seoPage.image_full_path} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location} />
                <meta property="twitter:title" content={props.seoPage.title} />
                {props.seoPage.description ?
                    <meta property="twitter:description" content={props.seoPage.description} />
                    :
                    null
                }
                <meta property="twitter:image" content={props.seoPage.image_full_path} />
            </Helmet>

            <Header
                title={props.generalData.geneticCounselorsSettings.page_title}
            />

            <Banner
                image={props.generalData.geneticCounselorsSettings.banner_image_path}
                title={props.generalData.geneticCounselorsSettings.banner_title}
            />

            {
                counselors ?
                    <>
                        <div className="container pt-lg-5 pt-4 pb-lg-3 filter-section position-relative" style={{ zIndex: 1 }}>
                            <div className="row pt-lg-4">
                                <h1 className="mb-lg-5 mb-4" animate="down">{props.generalData.geneticCounselorsSettings.search_title}</h1>
                                <div className="col-lg-3 col-10 position-relative pb-lg-0 pb-4" animate="up" style={{ zIndex: 3 }}>
                                    <h4>{props.generalData.geneticCounselorsSettings.search_name_label}</h4>
                                    <CustomSelect
                                        options={names}
                                        placeholder={props.generalData.geneticCounselorsSettings.search_name_placeholder}
                                        value={selectedNames}
                                        onChange={r => onChangeMultiSelect(selectedNames, setSelectedNames, r)}
                                    />
                                </div>
                                <div className="col-lg-3 col-10 position-relative pb-lg-0 pb-4" animate="up" style={{ zIndex: 2 }}>
                                    <h4>{props.generalData.geneticCounselorsSettings.search_country_label}</h4>
                                    <CustomSelect
                                        options={countries}
                                        placeholder={props.generalData.geneticCounselorsSettings.search_country_placeholder}
                                        value={selectedCountries}
                                        onChange={r => onChangeMultiSelect(selectedCountries, setSelectedCountries, r)}
                                    />
                                </div>
                                <div className="col-lg-3 col-10 position-relative pb-lg-0 pb-4" animate="up" style={{ zIndex: 1 }}>
                                    <h4>{props.generalData.geneticCounselorsSettings.search_speciality_label}</h4>
                                    <CustomSelect
                                        options={specialities}
                                        placeholder={props.generalData.geneticCounselorsSettings.search_speciality_placeholder}
                                        value={selectedSpecialties}
                                        onChange={r => onChangeMultiSelect(selectedSpecialties, setSelectedSpecialties, r)}
                                    />
                                </div>
                                <div className="col-lg-3 col-10" animate="up">
                                    <h4>{props.generalData.geneticCounselorsSettings.search_patient_facing_label}</h4>
                                    <CustomSelect
                                        options={patientFacings}
                                        placeholder={props.generalData.geneticCounselorsSettings.search_patient_facing_placeholder}
                                        value={selectedPatientFacings}
                                        onChange={r => onChangeMultiSelect(selectedPatientFacings, setSelectedPatiendFacings, r)}
                                    />
                                </div>
                            </div>
                            <div className="text-lg-end text-md-start text-sm-start text-start py-5">
                                <button className="filter-btn" onClick={clearFilters}>{props.generalData.geneticCounselorsSettings.filter_button_label}</button>
                            </div>
                        </div>
                        <div className="container py-5">
                            <div className="table-responsive">
                                <table className="table genetic-table table-hover ">
                                    <thead animate="left">
                                        <tr>
                                            <th className="filter-title" scope="col">{props.generalData.geneticCounselorsSettings.name_label}</th>
                                            <th className="filter-title" scope="col">{props.generalData.geneticCounselorsSettings.country_label}</th>
                                            <th className="filter-title" scope="col">{props.generalData.geneticCounselorsSettings.speciality_label}</th>
                                            <th className="filter-title" scope="col">{props.generalData.geneticCounselorsSettings.patient_facing_label}</th>
                                        </tr>
                                    </thead>

                                    <tbody animate="left">
                                        {
                                            counselors.map(boardMember => (
                                                (
                                                    filterByName(boardMember) ||
                                                    filterByCountries(boardMember) ||
                                                    filterBySpecialties(boardMember) ||
                                                    filterByPatientFacings(boardMember)
                                                ) ?
                                                    <tr key={boardMember.id} onClick={() => setMemberPopup(boardMember)}>
                                                        <td className="filter-details">{boardMember.name}</td>
                                                        <td className="filter-details">
                                                            {
                                                                boardMember.countries.map((country, index) => (
                                                                    <>
                                                                        <span>{country.name}</span>
                                                                        {
                                                                            boardMember.countries.length === index + 1 ?
                                                                                null
                                                                                :
                                                                                <span>, </span>
                                                                        }
                                                                    </>
                                                                ))
                                                            }

                                                        </td>
                                                        <td className="filter-details">
                                                            {
                                                                boardMember.specialities.map((specialty, index) => (
                                                                    <>
                                                                        <span>{specialty.name}</span>
                                                                        {
                                                                            boardMember.specialities.length === index + 1 ?
                                                                                null
                                                                                :
                                                                                <span>, </span>
                                                                        }
                                                                    </>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="filter-details">
                                                            {
                                                                boardMember.patient_facings.map((patientFacing, index) => (
                                                                    <>
                                                                        <span>{patientFacing.name}</span>
                                                                        {
                                                                            boardMember.patient_facings.length === index + 1 ?
                                                                                null
                                                                                :
                                                                                <span>, </span>
                                                                        }
                                                                    </>
                                                                ))
                                                            }
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                            ))
                                        }
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </>
                    :
                    <div className="lds-ellipsis mx-auto">
                        <div></div>
                        <div></div>
                    </div>
            }

            {
                counselors ?
                    <div className={"background-popup background-popup-blue " + (memberPopup ? "" : "fade-out")}>
                        <div className="container position-relative">
                            <div className="close-x" onClick={() => setMemberPopup(null)}>
                                <svg className="close-popup" id="Group_167" data-name="Group 167" xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82">
                                    <path id="Path_14" data-name="Path 14" d="M41,0A41,41,0,1,1,0,41,41,41,0,0,1,41,0Z" transform="translate(82 82) rotate(180)" fill="#9b4493" />
                                    <g id="Group_64" data-name="Group 64" transform="translate(0.001 -0.118)">
                                        <path className="strokeWidth" id="Path_57" data-name="Path 57" d="M780.269,584.943l-10.882,10.882,10.882,10.882" transform="translate(810.387 636.944) rotate(180)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                                        <path className="strokeWidth" id="Path_58" data-name="Path 58" d="M780.269,584.943l-10.882,10.882,10.882,10.882" transform="translate(-728.389 -554.708)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div className="container h-100 py-5 overflow-auto">
                            <div className="row justify-content-center align-items-start h-100 flex-row flex-md-row flex-sm-row">
                                <div className="col-lg-4 col-md-5 order-lg-1 order-2 mx-lg-5 ">
                                    <div className="mx-auto">
                                        <div className="ratio ratio-1x1 image-member">
                                            <img className="member-image" src={memberPopup?.image_path} alt="member" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 order-lg-2 order-md-2 order-1 mx-lg-5">
                                    <h3 className="mb-0">{memberPopup?.name}</h3>
                                    <h5 className="mb-4">{memberPopup?.speciality}</h5>
                                    <div dangerouslySetInnerHTML={{ __html: memberPopup?.description }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </Layout >
    )
}