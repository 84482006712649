import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import axios from "axios";
import ReactDOMServer from 'react-dom/server';
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
// import * as Scroll from 'react-scroll';
import { animateScroll } from 'react-scroll';

export default function About(props) {

    const [memberPopup, setMemberPopup] = useState(null);
    const [boardMembers, setBoardMembers] = useState();
    

    function findPos(obj) {
        var curtop = 0;
        if (obj.offsetParent) {
            do {
                curtop += obj.offsetTop;
            } while (obj = obj.offsetParent);
            return [curtop];
        }
    }

    useEffect(() => {
        window.triggerScroll();

        axios.post('board-members').then(response => {
            setBoardMembers(response.data);
            window.triggerScroll();

            if (document.getElementById('about-video-play')) {
                document.getElementById('about-video-play').addEventListener('click', function () {
                    document.getElementById('about-video-play').classList.add('hidden');
                    document.getElementById('about-video-image').classList.add('hidden');
                    document.getElementById('about-video').classList.remove('hidden');
                    document.getElementById('about-video').play();
                });
            }

            if (props.activeAboutSection) {
                animateScroll.scrollTo(findPos(document.querySelector('#' + props.activeAboutSection)));
            }
        });

        window.addEventListener('scroll', function () {
            props.setActiveAboutSection('');
        });
    }, []);

    useEffect(() => {
        if (props.activeAboutSection && boardMembers) {
            animateScroll.scrollTo( findPos(document.querySelector('#' + props.activeAboutSection)));
        }
    }, [props.activeAboutSection]);

    useEffect(() => {
        document.querySelector('body').style.overflow = memberPopup ? 'hidden' : null;
        document.querySelector('html').style.overflow = memberPopup ? 'hidden' : null;
    }, [memberPopup]);

    return (
        <Layout generalData={props.generalData} setActiveAboutSection={props.setActiveAboutSection} activePage="about">
            <Helmet>
                <title>{props.seoPage.title}</title>
                <meta name="title" content={props.seoPage.title} />
                {props.seoPage.description ?
                    <meta name="description" content={props.seoPage.description} />
                    :
                    null
                }
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location} />
                <meta property="og:title" content={props.seoPage.title} />
                {props.seoPage.description ?
                    <meta property="og:description" content={props.seoPage.description} />
                    :
                    null
                }
                <meta property="og:image" content={props.seoPage.image_full_path} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location} />
                <meta property="twitter:title" content={props.seoPage.title} />
                {props.seoPage.description ?
                    <meta property="twitter:description" content={props.seoPage.description} />
                    :
                    null
                }
                <meta property="twitter:image" content={props.seoPage.image_full_path} />
            </Helmet>

            <Header
                title={props.generalData.aboutSettings.page_title}
            />

            <div className="position-relative" id="mission" >
                <div className="container ">
                    <div className="row justify-content-end ">
                        <div className=" col-lg-6 col-md-12 index">
                            <div className="container position-relative">
                                <div className="purple-bg">
                                    <h3 className="mb-4 mt-lg-0 mt-3" animate="down">{props.generalData.aboutSettings.image_title}</h3>
                                    <div animate="down" dangerouslySetInnerHTML={{ __html: props.generalData.aboutSettings.image_description }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-section-1" id="about-section-1">
                        <div className="container">
                            {/* <div className="text-end">
                                <a rel="noreferrer" href="#about-section-2">
                                    <svg className="arrow-section" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 82 82">
                                        <g id="Group_117" data-name="Group 117" transform="translate(82 82) rotate(180)">
                                            <g id="Group_167" data-name="Group 167">
                                                <path id="Path_14" data-name="Path 14" d="M41,0A41,41,0,1,1,0,41,41,41,0,0,1,41,0Z" transform="translate(82) rotate(90)" fill="#9b4493" />
                                                <g id="Group_19" data-name="Group 19" transform="translate(1419.996 -22.45) rotate(90)">
                                                    <g id="Group_17" data-name="Group 17" transform="translate(51.45 1362.118)">
                                                        <path className="stroke-w" id="Path_10" data-name="Path 10" d="M786.265,584.943l-16.878,16.878L786.265,618.7" transform="translate(-769.387 -584.943)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="4" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="overlay-image" animate="left">
                    <img className="about-image" src={props.generalData.aboutSettings.image_path} alt="banner" />
                    <div className="opacity-about"></div>
                </div>
            </div>

            <div className="container pt-5" id="about-section-2">
                <div className="row py-5" >
                    <div className="col-lg-9 section-2">
                        <div dangerouslySetInnerHTML={{
                            __html: props.generalData.aboutSettings.content.replace(
                                '{%%video%%}',
                                ReactDOMServer.renderToString(
                                    <div className="ratio img-video">
                                        <video className="hidden w-100" src={props.generalData.aboutSettings.middle_video_path} controls id="about-video" />
                                        <img src={props.generalData.aboutSettings.middle_video_cover_path} alt="" id="about-video-image" />
                                        {
                                            props.generalData.aboutSettings.middle_video_path ?
                                                <img className="play-btn" src="/assets/images/play.svg" alt="" id="about-video-play" />
                                                :
                                                null
                                        }
                                    </div>
                                )
                            )
                        }} />
                    </div>
                </div>
            </div>

            <div>
                <div className="line"></div>
            </div>

            {
                boardMembers ?
                    <div className="container about-section-4 py-lg-5 py-4" >
                        <a rel="noreferrer" className="anchor" id="team"></a>
                        <div className="row pb-lg-0 pb-3 justify-content-center">
                            <h1 className="board-members-title ps-4" animate="left">{props.generalData.aboutSettings.board_title}</h1>

                            {
                                boardMembers.map(boardMember => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 text-center g-5 mobile-space" animate="left">
                                        <div className="mx-auto" >
                                            <div className="ratio ratio-1x1 swiper-image-member">
                                                <img className="swiper-image" src={boardMember.featured_image} alt="members" />
                                                <img className="member-hover" src="/assets/images/hover-circle.svg" alt="member" onClick={() => setMemberPopup(boardMember)} />
                                                <p className="member-name">{boardMember.full_name}</p>
                                            </div>
                                        </div>
                                        <div className="pt-3">
                                            <h3>{boardMember.full_name}</h3>
                                            <p className="mb-0">{boardMember.position}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    :
                    <div className="lds-ellipsis mx-auto">
                        <div></div>
                        <div></div>
                    </div>
            }

            {
                boardMembers ?
                    <div className={"background-popup " + (memberPopup ? "" : "fade-out")}>
                        <div className="container position-relative">
                            <div className="close-x" onClick={() => setMemberPopup(null)}>
                                <svg className="close-popup" id="Group_167" data-name="Group 167" xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82" >
                                    <path id="Path_14" data-name="Path 14" d="M41,0A41,41,0,1,1,0,41,41,41,0,0,1,41,0Z" transform="translate(82 82) rotate(180)" fill="#9b4493" />
                                    <g id="Group_64" data-name="Group 64" transform="translate(0.001 -0.118)">
                                        <path className="strokeWidth" id="Path_57" data-name="Path 57" d="M780.269,584.943l-10.882,10.882,10.882,10.882" transform="translate(810.387 636.944) rotate(180)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                                        <path className="strokeWidth" id="Path_58" data-name="Path 58" d="M780.269,584.943l-10.882,10.882,10.882,10.882" transform="translate(-728.389 -554.708)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                                    </g>
                                </svg>
                            </div>
                        </div>

                        <div className="container h-100 py-lg-5 py-sm-3 py-3 ">
                            <div className="row justify-content-center align-items-center h-100 flex-row flex-md-row flex-sm-row ">

                                <div className="col-lg-4 col-md-5 order-lg-1 order-2 mx-lg-5 ">
                                    <div className="mx-auto">
                                        <div className="ratio ratio-1x1 image-member">
                                            <img src={memberPopup?.featured_image} alt="member" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-5 order-lg-2 order-md-2 order-1 mx-lg-5">
                                    <div className="popup-content">
                                        <h3 className="mb-2">{memberPopup?.full_name}</h3>
                                        <h5 className="mb-2">{memberPopup?.position}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: memberPopup?.description }} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    :
                    null
            }



        </Layout >
    )
}

