import Layout from "../components/Layout";
import { useEffect, useState } from "react";
import Banner from "../components/Banner";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import ReactDOMServer from 'react-dom/server';
import { Helmet } from "react-helmet";

export default function BlogPost(props) {

    // const [videoOpen, setVideoOpen] = useState(false);
    const [blogSettings, setBlogSettings] = useState();
    const [blog, setBlog] = useState();

    let { slug } = useParams();

    useEffect(() => {
        axios.post('blog-settings').then(response => {
            setBlogSettings(response.data);
            window.triggerScroll();
        });

        axios.post('blog-posts', {
            'custom_validation': [
                {
                    'constraint': 'where',
                    'value': ['slug', slug]
                }
            ]
        }).then(response => {
            setBlog(response.data[0]);
            window.triggerScroll();

            if (document.getElementById('about-video-play')) {
                document.getElementById('about-video-play').addEventListener('click', function () {
                    document.getElementById('about-video-play').classList.add('hidden');
                    document.getElementById('about-video-image').classList.add('hidden');
                    document.getElementById('about-video').classList.remove('hidden');
                    document.getElementById('about-video').play();
                });
            }
        });
    }, []);

    return (
        <Layout generalData={props.generalData} setActiveAboutSection={props.setActiveAboutSection} activePage="blog">
            <Helmet>
                <title>{blog?.title}</title>
                <meta name="title" content={blog?.title} />
                <meta name="description" content={blog?.description} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location} />
                <meta property="og:title" content={blog?.title} />
                <meta property="og:description" content={blog?.og_description} />
                <meta property="og:image" content={blog?.og_image_path} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location} />
                <meta property="twitter:title" content={blog?.title} />
                <meta property="twitter:description" content={blog?.og_description} />
                <meta property="twitter:image" content={blog?.og_image_path} />
            </Helmet>

            {blog ?
                <>

                    <div className="container main-header pt-lg-5 pt-4 pb-2">
                        <div className="d-flex align-items-center">
                            <Link to="/blog">
                                <svg className="arrow-back" xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
                                    <circle id="Ellipse_15" data-name="Ellipse 15" cx="28" cy="28" r="28" fill="#9b4493" />
                                    <g id="Group_18" data-name="Group 18" transform="translate(14.988 17.051)">
                                        <g id="Group_17" data-name="Group 17">
                                            <line className="stroke-w" id="Line_8" data-name="Line 8" x1="25.621" transform="translate(0.429 11.232)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                                            <path className="stroke-w" id="Path_10" data-name="Path 10" d="M780.269,584.943l-10.882,10.882,10.882,10.882" transform="translate(-769.387 -584.943)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                                        </g>
                                    </g>
                                </svg>
                            </Link>
                            <h1 className="ms-3 mb-0">Blog Post</h1>
                        </div>
                    </div>

                    <Banner
                        image={blog?.banner_image}
                    // title={blog?.title}
                    />

                    <div className="container py-5 blog-post">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <h1 className="mb-3">{blog?.description}</h1>
                                <div className="row">
                                    <div className="col-lg-5 col-md-6 col-sm-12 col-12">
                                        <div className="line mb-4"> </div>
                                        <div className="d-flex align-items-center">
                                            <a without rel="noreferrer"  href={blog?.author.profile_url} target="_blank" className="author me-3">{blog?.author_name}</a>
                                        </div>
                                    </div>
                                </div>


                                <div className="style-table" dangerouslySetInnerHTML={{
                                    __html: blog?.content.replace(
                                        '{%%video%%}',
                                        ReactDOMServer.renderToString(
                                            <div className="ratio img-video">
                                                <video className="hidden w-100" src={props.generalData.aboutSettings.middle_video_path} controls id="about-video" />
                                                <img src={props.generalData.aboutSettings.middle_video_cover_path} alt="" id="about-video-image" />
                                                <img className="play-btn" src="/assets/images/play.svg" alt="" id="about-video-play" />
                                            </div>
                                        )
                                    )
                                }} />

                            </div>
                        </div>
                    </div>
                </>
                :
                <div className="lds-ellipsis mx-auto">
                    <div></div>
                    <div></div>
                </div>
            }

        </Layout>
    )
}