import { Link } from "react-router-dom";

export default function Blog(props) {
    return (
        <div className="pb-5">
            <div className="row align-items-center g-0 shadow p-lg-0 p-2">
                <div className="col-lg-5 position-relative">
                    <Link to={"/blog/" + props.slug}>
                        <div className="section4-image" animate="left">
                            <img className="ratio ratio-4x3 blog-banner" src={props.image} alt="blogpost" />
                        </div>
                    </Link>
                </div>
                <div className="col-lg-7" animate="right">
                    <Link to={"/blog/" + props.slug}>
                        <div className="mx-lg-3 p-lg-2 ">
                            <h3 className="mt-3 mt-lg-0">{props.title}</h3>
                            <div className="d-flex align-items-center">
                                <a href={props.credentials} target="_blank" rel="noreferrer" className="author mt-1 mt-lg-0 me-3">{props.author}</a>
                            </div>
                            <p className="mt-lg-3 mb-lg-3 mb-4">{props.description}</p>
                            <span className="read-more">{props.button}</span>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}