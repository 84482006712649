export default function Banner(props) {
    return (
        <div className="ratio padding-banner" animate="down">
            <img className="banner-header" src={props.image} alt="header-banner" />
            <div className="layer"></div>
            <div className="head-title">
                <div className="container">
                    <h1 animate="down">{props.title}</h1>
                </div>
            </div>
        </div >

    )
}